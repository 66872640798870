import { theme } from '@chakra-ui/react';

export const customColorNames = [
  'bg1',
  'bg2',
  'bg3',
  'bg4',
  'text1',
  'text2',
  'text3',
  'text4',
  'border1',
  'border2',
  'border3',
  'border3Focus',
  'action',
  'focus',
  'link',
  'linkHover',
  'linkPressed',
  'editorBg',
  'editorText',
  'executionRunning',
  'executionError',
  'executionSuccess',
  'activeMenu',
  'dashboardBg',
];

const baseColors = {
  blue: {
    50: '#edf2ff',
    100: '#dbe4ff',
    200: '#bac8ff',
    300: '#91a7ff',
    400: '#748ffc',
    500: '#5c7cfa',
    600: '#4c6ef5',
    700: '#4263eb',
    800: '#3b5bdb',
    900: '#364fc7',
  },
  green: {
    50: '#EBFBEE',
    100: '#D3F9D8',
    200: '#B2F2BB',
    300: '#8CE99A',
    400: '#69DB7C',
    500: '#51CF66',
    600: '#40C057',
    700: '#37B24D',
    800: '#2F9E44',
    900: '#2B8A3E',
  },
  lightgray: {
    50: '#F8F9FA',
    100: '#ebedee',
    200: '#dee0e3',
    300: '#d1d4d8',
    400: '#c4c8cc',
    500: '#b7bcc1',
    600: '#abb0b6',
    700: '#9ea5ab',
    800: '#9299a1',
    900: '#868e96',
  },
  gray: {
    50: '#F8F9FA',
    100: '#f1f3f5',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#868E96',
    700: '#495057',
    800: '#343A40',
    900: '#212529',
  },
  black: {
    50: '#343A40',
    100: '#343A40',
    200: '#343A40',
    300: '#343A40',
    400: '#343A40',
    500: '#343A40',
    600: '#212529',
    700: '#212529',
    800: '#212529',
    900: '#212529',
  },
  white: {
    50: '#dee2e6',
    100: '#dee2e6',
    200: '#dee2e6',
    300: '#dee2e6',
    400: '#dee2e6',
    500: '#dee2e6',
    600: '#F8F9FA',
    700: '#F8F9FA',
    800: '#F8F9FA',
    900: '#F8F9FA',
  },
  yellow: {
    50: '#FFF9DB',
    100: '#FFF3BF',
    200: '#FFEC99',
    300: '#FFE066',
    400: '#FFD43B',
    500: '#FCC419',
    600: '#FAB005',
    700: '#F59F00',
    800: '#F08C00',
    900: '#E67700',
  },
  red: {
    50: '#FFF5F5',
    100: '#FFF5F5',
    200: '#FFE3E3',
    300: '#FFC9C9',
    400: '#FF8787',
    500: '#FF6B6B',
    600: '#FA5252',
    700: '#F03E3E',
    800: '#E03131',
    900: '#C92A2A',
  },
  nodeimport: theme.colors.cyan,
  nodetransform: theme.colors.purple,
  nodeexport: theme.colors.pink,
  // when you add a new color here, you also need to add it to the colorNames
  dark: {
    bg1: '#141414',
    bg2: '#292929',
    bg3: '#333333',
    bg4: '#3d3d3d',
    bgGraph: '#292929',
    text1: '#ffffff',
    text2: '#ebebeb',
    text3: '#d5d5d5',
    text4: '#808080',
    border3: '#333333',
    border2: '#3D3D3D',
    border1: '#525252',
    border3Focus: '#4C6EF5',
    action: '#4C6EF5',
    link: '#4C6EF5',
    linkHover: '#1345F3',
    linkPressed: '#7819F1',
    editorBg: '#292929',
    editorText: '#ffffff',
    executionRunning: '#748FFC',
    executionError: '#FF6B6B',
    executionSuccess: '#3BB346',
    activeMenu: '#666',
    dashboardBg: '#292929',
  },
  light: {
    bg1: '#ffffff',
    bg2: '#fdfdfd',
    bg3: '#F7F8F8',
    bg4: '#f1f3f5',
    bgGraph: '#F5F5F5',
    text1: '#212529',
    text2: '#495057',
    text3: '#868e96',
    text4: '#ced4da',
    border1: '#E9ECEF',
    border2: '#DEE2E6',
    border3: '#CED4DA',
    border3Focus: '#4C6EF5',
    action: '#4C6EF5',
    link: '#4C6EF5',
    linkHover: '#1345F3',
    linkPressed: '#7819F1',
    editorBg: '#f6f7f9',
    editorText: '#212529',
    executionRunning: '#748FFC',
    executionError: '#FF6B6B',
    executionSuccess: '#3BB346',
    activeMenu: '#E4E9FE',
    configured: '#12b886',
    dashboardBg: '#fcfcfc',
  },
};

// add all custom names as a css variable to the theme
customColorNames.forEach((name) => {
  // @ts-ignore
  baseColors[name] = `var(--${name})`;
});

export const colors = baseColors;
