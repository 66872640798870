import { useEffect } from 'react';
import { useColorMode } from '@chakra-ui/react';

import useHandleOrgCreationCallback from 'hooks/useHandleOrgCreationCallback';
import { useOrganizationAppEffects } from 'hooks/useOrganization';

type AppEffectsProps = {};

// put any effects that need to be run on every page here
export default function AppEffects({}: AppEffectsProps) {
  useOrganizationAppEffects();
  useHandleOrgCreationCallback();

  const { colorMode, toggleColorMode } = useColorMode();
  // if colorMode is set to dark, toggle it to light
  useEffect(() => {
    if (colorMode === 'dark') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  return <></>;
}
