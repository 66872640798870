/////////////////////////// Account Endpoints
export function getAccountsURL() {
  return '/accounts/me';
}

export function getAccountOrganizationInvitationsURL() {
  return '/accounts/invitations';
}

export function getAccountNotificationsURL() {
  return '/accounts/notifications';
}

export function getSetupAccountURL() {
  return `/accounts/setup`;
}

export function getResendVerificationEmailURL() {
  return '/accounts/resend_verification_email';
}

/////////////////////////// Organization Endpoints

// export function createOrganizationURL() {
//   return '/organizations';
// }
//
// export function getOrganizationsURL() {
//   return '/organizations';
// }
//
// export function getOrganizationURL(organizationUID: string) {
//   return `/organizations/${organizationUID}`;
// }
//
// export function updateOrganizationURL(organizationUID: string) {
//   return `/organizations/${organizationUID}`;
// }
//
// export function deleteOrganizationURL(organizationUID: string) {
//   return `/organizations/${organizationUID}`;
// }

/////////////////////////// Organization Billing Endpoints

export function addOrganizationURL() {
  return `/organizations/billing`;
}

export function getStripeBillingURL(organizationUID: string) {
  return `/organizations/${organizationUID}/billing`;
}

export function getNewOrganizationURL(sessionID: string) {
  return `/organizations/billing/session/${sessionID}`;
}

export function cloneOrganizationURL(organizationUID?: string) {
  return `/organizations/${organizationUID}/clone`;
}

/////////////////////////// Organization Invites Endpoints

export function getOrganizationInvitationsURL(organizationUID: string) {
  return `/organizations/${organizationUID}/invitations`;
}

export function createOrganizationInvitationsURL(organizationUID: string) {
  return `/organizations/${organizationUID}/invitations`;
}

export function getJoinOrganizationURL(inviteUID: string) {
  return `/invitations/${inviteUID}/join`;
}

export function deleteJoinOrganizationURL(inviteUID: string) {
  return `/invitations/${inviteUID}`;
}

export function getOrganizationInviteURL(inviteUID: string) {
  return `/invitations/${inviteUID}`;
}

export function getResendOrganizationInviteURL(inviteUID: string) {
  return `/invitations/${inviteUID}/resend`;
}

/////////////////////////// Organization Members Endpoints
export function getOrganizationMembersURL(graphUID: string) {
  return `/graphs/${graphUID}/members`;
}

export function getRemoveOrganizationMemberURL(organizationUID: string, uid: string) {
  return `/organizations/${organizationUID}/members/${uid}`;
}

/////////////////////////// Graph Endpoints

export function getGraphsURL(organizationUID: string) {
  return `/organizations/${organizationUID}/graphs`;
}

export function getCreateGraphsURL() {
  return '/graphs';
}

export function getGraphURL(graphUID: string) {
  return `/graphs/${graphUID}`;
}

export function updateGraphURL(graphUID: string) {
  return `/graphs/${graphUID}`;
}

export function deleteGraphURL(graphUID: string) {
  return `/graphs/${graphUID}`;
}

export function getGraphAvailableTablesURL(graphUID: string) {
  return `/graphs/${graphUID}/available_tables`;
}

export function getGraphTablesURL(graphUID: string) {
  return `/graphs/${graphUID}/tables`;
}

export function updateGraphTablesURL(graphUID: string) {
  return `/graphs/${graphUID}/tables`;
}

export function getGraphStorageURL(graphUID: string) {
  return `/graphs/${graphUID}/storage`;
}

export function getGraphLatestExecutionsURL(graphUID: string) {
  return `/graphs/${graphUID}/latest_executions`;
}

export function addTableFromCsvURL(graphUID: string) {
  return `/graphs/${graphUID}/csv`;
}

/////////////////////////// Websocket Endpoints

function removeHttp(url: string) {
  return url.replace(/^https?:\/\//, '');
}

export function getGraphWebSocketURL(graphUID: string) {
  const protocol = (window?.location?.protocol === 'https:' ? 'wss' : 'ws') + '://';
  const websocketPrefix = 'api/ws';
  const webSocketURL = protocol + removeHttp(process.env.NEXT_PUBLIC_API_URL + websocketPrefix);
  return `${webSocketURL}/graphs/${graphUID}`;
}

/////////////////////////// Table Endpoints

export function getTableDataURL(tableUid: string) {
  return `/tables/${tableUid}/data`;
}

export function getTableDataCountURL(tableUid: string) {
  return `/tables/${tableUid}/data/count`;
}

export function getTableMetadataURL(tableUid: string) {
  return `/tables/${tableUid}/metadata`;
}

export function getTableDownloadDataURL(tableUid: string) {
  return `/tables/${tableUid}/download_data`;
}

export function updateTableURL(tableUid: string) {
  return `/tables/${tableUid}`;
}

export function refreshTableMetadataURL(graphUID: string) {
  return `/graphs/${graphUID}/table_metadata_refresh`;
}

/////////////////////////// Storage Endpoints

export function getStoragesURL(organizationUID: string) {
  return `/organizations/${organizationUID}/storages`;
}

export function addStorageURL(organizationUID: string) {
  return `/organizations/${organizationUID}/storages`;
}

export function getStorageURL(storageUid: string) {
  return `/storages/${storageUid}`;
}

export function updateStorageURL(storageUid: string) {
  return `/storages/${storageUid}`;
}

export function deleteStorageURL(storageUid: string) {
  return `/storages/${storageUid}`;
}

export function refreshStorageURL(storageUid: string) {
  return `/storages/${storageUid}/refresh`;
}

/////////////////////////// Analysis Endpoints

export function getGraphQueriesURL(graphUID: string) {
  return `/graphs/${graphUID}/analyses`;
}

export function getQueryURL(queryUID: string) {
  return `/analyses/${queryUID}`;
}

export function updateQueryURL(queryUID: string) {
  return `/analyses/${queryUID}`;
}

export function deleteQueryURL(queryUID: string) {
  return `/analyses/${queryUID}`;
}

export function triggerQueryURL(queryUID: string) {
  return `/analyses/${queryUID}/trigger`;
}

export function queryExecutionsURL(queryUID: string) {
  return `/analyses/${queryUID}/executions`;
}

export function queryLatestExecutionURL(queryUID: string) {
  return `/analyses/${queryUID}/latest_execution`;
}

export function updateChartURL(chartUID: string) {
  return `/charts/${chartUID}`;
}

export function requestChartChangesUrl(chartUID: string) {
  return `/charts/${chartUID}/request_changes`;
}

export function getChartUrlURL(chartUID?: string) {
  return `/charts/${chartUID}/url`;
}

export function getChartSpecURL(chartUID?: string) {
  return `/charts/${chartUID}/full_spec`;
}

/////////////////////////// Document Endpoints

export function getDocumentsURL(graphUID: string) {
  return `/graphs/${graphUID}/documents`;
}

export function addDocumentURL(graphUID: string) {
  return `/graphs/${graphUID}/documents`;
}

export function getDocumentURL(documentUID: string) {
  return `/documents/${documentUID}`;
}

export function updateDocumentURL(documentUID: string) {
  return `/documents/${documentUID}`;
}

export function deleteDocumentURL(documentUID: string) {
  return `/documents/${documentUID}`;
}

/////////////////////////// Metric Endpoints

export function getMetricsURL(graphUID: string) {
  return `/graphs/${graphUID}/metrics`;
}

export function getMetricURL(metricUID: string) {
  return `/metrics/${metricUID}`;
}

export function updateMetricURL(metricUID: string) {
  return `/metrics/${metricUID}`;
}

export function deleteMetricURL(metricUID: string) {
  return `/metrics/${metricUID}`;
}

export function saveMetricsURL(graphUID: string) {
  return `/graphs/${graphUID}/metrics/save_metrics`;
}

export function generateMetricsURL(graphUID: string) {
  return `/graphs/${graphUID}/metrics/generate_metrics`;
}

/////////////////////////// Chat Endpoints

export function getChatsURL(graphUID: string) {
  return `/graphs/${graphUID}/chats`;
}

export function addChatURL(graphUID: string) {
  return `/graphs/${graphUID}/chats`;
}

export function getChatURL(chatUID: string) {
  return `/chats/${chatUID}`;
}

export function getChatQueriesURL(chatUID: string) {
  return `/chats/${chatUID}/analyses`;
}

export function getChatAiCompletionsURL(chatUID: string) {
  return `chats/${chatUID}/ai_completions`;
}

export function deleteChatURL(chatUID: string) {
  return `/chats/${chatUID}`;
}

export function getChatMessagesURL(chatUID: string) {
  return `/chats/${chatUID}/messages`;
}

export function addChatMessageURL(chatUID: string) {
  return `/chats/${chatUID}/messages`;
}

export function getChatMessageURL(messageUID?: string) {
  return `/messages/${messageUID}`;
}

export function editChatMessageURL(messageUID?: string) {
  return `/messages/${messageUID}`;
}

export function setBadResponseMessageURL(messageUID: string) {
  return `/messages/${messageUID}/bad_response`;
}

export function getChatMessageAbortURL(messageUID?: string) {
  return `/messages/${messageUID}/abort`;
}

export function getChatSuggestions(graphUID: string) {
  return `/graphs/${graphUID}/chat_suggestions`;
}

export function updateChat(chatUID: string) {
  return `/chats/${chatUID}`;
}

export function askAnExpertURL(messageUID: string) {
  return `/messages/${messageUID}/ask_an_expert`;
}

/////////////////////////// Integration Endpoints

export function getGraphSlackStatusURL(graphUID: string): string {
  return `/graphs/${graphUID}/integrations/slack`;
}

export function updateGraphSlackStatusURL(graphUID: string): string {
  return `/graphs/${graphUID}/integrations/slack`;
}

export function deleteGraphSlackStatusURL(graphUID: string): string {
  return `/graphs/${graphUID}/integrations/slack`;
}
