import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import config from 'config';
import { useAuth } from 'contexts/AuthContext';
import useStore, { GlobalState } from 'state/globalState';

function useOrganization() {
  const setOrganizationUID = useStore((s) => s.setOrganizationUID);
  const organizationUID = useStore((s) => s.organizationUID);

  const { account } = useAuth();

  const organizations = useMemo(() => {
    return account?.organizations || null;
  }, [account?.organizations]);

  const setOrganization = useCallback(
    (newOrgUID: string | null) => {
      if (
        typeof window !== 'undefined' &&
        window.localStorage &&
        newOrgUID &&
        organizations &&
        Object.keys(organizations).includes(newOrgUID)
      ) {
        window.localStorage.setItem(config.localstorage.organizationUID, newOrgUID);
      }
      setOrganizationUID(newOrgUID);
    },
    [setOrganizationUID, organizations],
  );

  const organization = useMemo(() => {
    if (organizationUID && organizations && Object.keys(organizations).includes(organizationUID)) {
      return organizations[organizationUID];
    } else {
      return null;
    }
  }, [organizations, organizationUID]);

  return { setOrganization, organization, organizationUID };
}

function useOrganizationAppEffects() {
  const { setOrganization, organizationUID } = useOrganization();
  const { account } = useAuth();
  const router = useRouter();

  const organizations = useMemo(() => {
    return account?.organizations || null;
  }, [account?.organizations]);

  useEffect(() => {
    // only do this if we're not on the graph page
    if (!router.pathname.startsWith('/app')) {
      // when we load orgs, we check if there is a valid org in localstorage
      // otherwise we pick the first one to be the current org
      if (organizations && organizationUID === null) {
        let newOrgUID = null;
        if (typeof window !== 'undefined' && window.localStorage) {
          const localStorageOrgUID = window.localStorage.getItem(
            config.localstorage.organizationUID,
          );
          // check if the org uid is a valid org for the account
          if (localStorageOrgUID && Object.keys(organizations).includes(localStorageOrgUID)) {
            newOrgUID = localStorageOrgUID;
          }
        }
        if (!newOrgUID && Object.keys(organizations).length > 0) {
          newOrgUID = Object.keys(organizations)[0];
        }
        if (newOrgUID) {
          setOrganization(newOrgUID);
        }
      }
    }
  }, [setOrganization, organizations, organizationUID, router.pathname]);
}

function useOrganizationUID(): string {
  return useStore((s: GlobalState) => s.organizationUID)!;
}

export default useOrganization;

export { useOrganizationAppEffects, useOrganizationUID };
