import { ReactNode, useMemo } from 'react';
import { createStandaloneToast } from '@chakra-ui/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { isProduction } from 'config';

type CustomQueryClientProviderProps = {
  children: ReactNode;
};

function CustomQueryClientProvider({ children }: CustomQueryClientProviderProps) {
  const { toast } = createStandaloneToast();

  const queryClient = useMemo(() => {
    const toasty = (error: any) => {
      let toastID: string | undefined = undefined;

      const errorMessage = String(error.message);
      // skip for incomplete setup and unverified email
      if (errorMessage === 'incomplete setup' || errorMessage === 'unverified email') {
        return;
      }

      // skip for graph 404 or 403 errors
      if (
        error?.response?.config?.url?.startsWith('/graphs') &&
        (error?.response?.status === 404 || error?.response?.status === 403)
      ) {
        return;
      }

      // skip 422 errors from generate metric endpoint
      if (
        error?.response?.config?.url?.endsWith('/generate_metrics') &&
        error?.response?.status === 422
      ) {
        return;
      }

      // skip 422 errors from link storage endpoint -> this tells us we have permissions errors for creating a schema
      if (error?.response?.config?.url?.startsWith('/storage') && error?.response?.status === 422) {
        return;
      }

      // skip for cloning org
      if (error?.response?.config?.url?.endsWith('/clone')) {
        return;
      }

      // skip for storage connection errors
      if (error?.response?.config?.url?.startsWith('/storage') && error?.response?.status === 424) {
        return;
      }

      // skip for invalid invites since we already show an error message on the screen
      if (errorMessage.startsWith('OrganizationInvitation matching query does not exist')) {
        return;
      }

      let duration = null;
      if (errorMessage.startsWith('You can only resend an invitation email once every hour.')) {
        duration = 5000;
      }

      // if 424 on /organizations, don't show a toast -> we handle that error separately
      const databaseError =
        error?.response?.status === 424 &&
        !error?.response?.config?.url?.startsWith('/organizations');

      if (databaseError) {
        toastID = 'Database Error';
      }

      if (typeof error.message) {
        // Show an error toast if:
        // - we're not on prod
        // - we get a 402 (UsageLimitExceeded) error
        // - we get a database error
        if (!isProduction || error?.response?.status === 402 || databaseError) {
          const description = JSON.stringify(error.message) || 'An error occurred.';
          // prevent duplicate toasts
          if (!toast.isActive(description)) {
            toast({
              id: toastID || description,
              description: description,
              status: 'error',
              isClosable: true,
              duration: duration,
            });
          }
        }
      }
      console.error(error.message || 'An error occurred.');
    };
    return new QueryClient({
      queryCache: new QueryCache({
        onError: toasty,
      }),
      mutationCache: new MutationCache({
        onError: toasty,
      }),
      defaultOptions: {
        queries: {
          staleTime: Infinity,
        },
      },
    });
    // Important: don't put anything in the deps array. It's important that the query client only gets created once
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default CustomQueryClientProvider;
