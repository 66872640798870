import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const defaultDatabaseModalProps: DatabaseModalProps = {
  action: 'hidden',
  storageUid: undefined,
  error: undefined,
};

export type DatabaseModalProps = {
  action: 'edit' | 'create' | 'hidden';
  storageUid?: string;
  error?: string;
};

export type GlobalState = {
  organizationUID: string | null;
  databaseModalProps: DatabaseModalProps;
  showSlackIntegrationModal: boolean;
  setOrganizationUID: (organizationUID: string | null) => void;
  setDatabaseModalProps: (databaseModalProps: DatabaseModalProps) => void;
  setShowSlackIntegrationModal: (showSlackIntegrationModal: boolean) => void;
};

const useStore = create<GlobalState>()(
  devtools(
    (set) => ({
      organizationUID: null,
      databaseModalProps: defaultDatabaseModalProps,
      showSlackIntegrationModal: false,
      setOrganizationUID: (organizationUID: string | null) => {
        set({
          organizationUID,
        });
      },

      setDatabaseModalProps: (databaseModalProps: DatabaseModalProps) => {
        set({
          databaseModalProps: { ...defaultDatabaseModalProps, ...databaseModalProps },
        });
      },

      setShowSlackIntegrationModal: (showSlackIntegrationModal: boolean) => {
        set({ showSlackIntegrationModal });
      },
    }),
    { enabled: process.env.NODE_ENV === 'development' },
  ),
);

export default useStore;
