import { linkedInTrack } from 'nextjs-linkedin-insight-tag';

import { AccountSetupData } from 'hooks/api/accounts/useSetupAccount';
import { Account } from 'types/api';

export const FB_PIXEL_ID = '703925617966196';
const LI_PIXEL_ID = '16667916';

export const LI_CONVERSION = () => {
  if (!isProduction) {
    return;
  }
  linkedInTrack(LI_PIXEL_ID);
};

const isProduction = process.env.NEXT_PUBLIC_API_URL?.includes('api-production');

// https://github.com/vercel/next.js/tree/canary/examples/with-facebook-pixel
export const FB_PIXEL_INIT = (account: Account) => {
  if (!isProduction) {
    return;
  }
  window.fbq(
    'init',
    FB_PIXEL_ID,
    // @ts-ignore
    {
      em: account.email, //Values will be hashed automatically by the pixel using SHA-256
      fn: account.first_name,
      ln: account.last_name,
    },
    {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    },
  );
};

export const FB_PIXEL_SUBSCRIBE = (account: AccountSetupData) => {
  if (!isProduction) {
    return;
  }
  // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  window.fbq('track', 'Subscribe', account);
};

export const FB_PIXEL_PAGEVIEW = () => {
  if (!isProduction) {
    return;
  }
  window.fbq('track', 'PageView');
};
