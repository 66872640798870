import { useEffect, useState } from 'react';

export function checkInIframe() {
  return (
    (typeof window !== 'undefined' && !!window.frameElement) ||
    window.self !== window.top ||
    !!window.frameElement
  );
}

const useInIframe = (): boolean => {
  const [inIframe, setInIframe] = useState(false);
  useEffect(() => {
    setInIframe(checkInIframe());
  }, [setInIframe]);
  return inIframe;
};

export default useInIframe;
