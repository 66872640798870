import Axios from 'axios';

var Qs = require('qs');

const ApiPrefix = 'api/app';

const ApiBaseURL = process.env.NEXT_PUBLIC_API_URL + ApiPrefix;

const axios = Axios.create({
  baseURL: ApiBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export default axios;

const AxiosApiNoAuth = Axios.create({
  baseURL: ApiBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
});

export { AxiosApiNoAuth, ApiBaseURL };
