import { useEffect } from 'react';
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AuthErrorModal from 'modals/AuthErrorModal';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { inter } from 'styles/fonts';
import theme from 'styles/theme';

import AppEffects from 'components/AppEffects';
import AxiosExtend from 'components/AxiosExtend';
import CustomQueryClientProvider from 'components/CustomQueryClientProvider';
import HelpMenu from 'components/HelpMenu';
import VersionCheck from 'components/VersionCheck';
import AuthProvider from 'contexts/AuthContext';
import { FB_PIXEL_ID, FB_PIXEL_PAGEVIEW } from 'utils/fpixel';
import 'styles/AppStyles.css';
import 'styles/typing.css';
import 'styles/scrollbars.css';
import 'styles/codemirror.css';
import 'styles/SchemaEditor.css';
import 'styles/tableStyles.css';
import 'styles/PrintStyles.css';
import 'rc-tree/assets/index.css';
import 'katex/dist/katex.min.css';

// load this dynamically so we can access window object
const Atlas = dynamic(() => import('components/Atlas'), { ssr: false });

const TESTING = process.env.NEXT_PUBLIC_ENV === 'test';

const AuthDomain: string = process.env.NEXT_PUBLIC_AUTH_DOMAIN!;
const AuthClientId: string = process.env.NEXT_PUBLIC_AUTH_CLIENT_ID!;
var AuthRedirectUri: string = process.env.NEXT_PUBLIC_AUTH_REDIRECT_URI!;
if (typeof window !== 'undefined') {
  AuthRedirectUri = window.location.origin + '/home';
}
const AuthAudience: string = process.env.NEXT_PUBLIC_AUTH_AUDIENCE!;

const onAuthRedirectCallback = (appState?: AppState) => {
  if (appState?.inviteUid) {
    // redirect to the invite page if there's an inviteUid in the appState
    Router.replace({
      pathname: `/join/${appState.inviteUid}`,
      query: { joinedOrg: true },
    });
  } else {
    Router.replace(appState?.returnTo || '/');
  }
};

function PatternsApp({ Component, pageProps, router }: AppProps) {
  const toast = useToast();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog) {
      posthog.init('phc_KXATgYVxXeKDju16thkwkVJEnME1QLNLb5xDaY2jXma', {
        api_host: 'https://app.posthog.com',
      });
    }
  }, []);

  const authArgs: Auth0ProviderOptions = {
    domain: AuthDomain,
    clientId: AuthClientId,
    redirectUri: AuthRedirectUri,
    audience: AuthAudience,
    cacheLocation: 'localstorage', // Using localstorage since some browsers such as Safari do not persist tokens between tabs or retrieve tokens silently
    useRefreshTokens: true,
    onRedirectCallback: onAuthRedirectCallback,
    skipRedirectCallback: TESTING,
  };

  useEffect(() => {
    const handleRouteChange = () => {
      toast.closeAll(); // close toasts on page navigation
    };
    router.events.on('routeChangeStart', handleRouteChange);
    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, toast]);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    FB_PIXEL_PAGEVIEW();
    const handleRouteChange = () => {
      FB_PIXEL_PAGEVIEW();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <main className={inter.className}>
      <Head>
        <title>Patterns</title>
        <meta name="description" content="The fastest way to generate data-driven reports" />
        <link rel="icon" href="/logos/favicon.ico" />
        <meta name="fragment" content="!" />
      </Head>

      <ChakraProvider theme={theme} portalZIndex={10000}>
        <CustomQueryClientProvider>
          {!TESTING && <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />}
          <Auth0Provider {...authArgs}>
            <AuthProvider>
              <AxiosExtend />
              <Atlas />
              <Component {...pageProps} />
              <AuthErrorModal />
              <VersionCheck />
              <HelpMenu />
              <AppEffects />
            </AuthProvider>
          </Auth0Provider>
        </CustomQueryClientProvider>
      </ChakraProvider>

      {process.env.NEXT_PUBLIC_ENABLE_APPCUES === 'true' && (
        <div>
          <Script id="appcues-settings">
            {`window.AppcuesSettings = { enableURLDetection: true };`}
          </Script>
          <Script src="//fast.appcues.com/128504.js" />
        </div>
      )}

      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${FB_PIXEL_ID});
          `,
        }}
      />

      {/* Global Site Code Pixel - Linkedin */}
      <Script strategy="afterInteractive" id="linkedin">
        {`_linkedin_partner_id = "4435708";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  (function(l) {
  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
  window.lintrk.q=[]}
  var s = document.getElementsByTagName("script")[0];
  var b = document.createElement("script");
  b.type = "text/javascript";b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </Script>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src="https://px.ads.linkedin.com/collect/?pid=4435708&fmt=gif"
      />
    </main>
  );
}

export default PatternsApp;
