import { useEffect } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';

import ModalComponent from 'components/Modal';
import { useAuth } from 'contexts/AuthContext';

function AuthErrorModal() {
  const { authError } = useAuth();

  const { isOpen: isVisible, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (authError) {
      Sentry.captureException(authError);
      onOpen();
    }
  }, [authError, onOpen]);

  return (
    <ModalComponent
      size="lg"
      showFooter={false}
      onClose={() => onClose()}
      isOpen={isVisible}
      modalBodyProps={{ margin: 0, padding: 0 }}
      closeOnOverlayClick={false}
    >
      <Alert status="error" borderRadius="md">
        <AlertIcon />
        <Box width={'100%'}>
          <AlertTitle>Error authenticating</AlertTitle>
          <AlertDescription>Please refresh the page or contact Patterns support.</AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Alert>
    </ModalComponent>
  );
}

export default AuthErrorModal;
