import { Box } from '@chakra-ui/react';

import useInIframe from 'hooks/useInIframe';

function HelpMenu() {
  const inIframe = useInIframe();

  return (
    <>
      {!inIframe && (
        <Box
          position="fixed"
          bottom={2}
          left={0}
          zIndex={100}
          w="38px"
          h="38px"
          id="launcher-button"
        />
      )}
    </>
  );
}

export default HelpMenu;
