const isProduction = process.env.NEXT_PUBLIC_API_URL?.includes('api-production');

type PatternsAppConfig = {
  defaultPathname: string;
  localstorage: {
    organizationUID: string;
  };
};

const config: PatternsAppConfig = {
  defaultPathname: '/home',
  localstorage: { organizationUID: 'basis:organization_uid' },
};

export { isProduction };

export default config;
