import { useCallback, useEffect, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import useGetNewOrganization from 'hooks/api/organizations/useGetNewOrganization';

/**
 * These parameters are passed back as a callback when we try to create a new billing account from Stripe
 */
function useHandleOrgCreationCallback() {
  const router = useRouter();

  const createOrgSuccess = useMemo(() => {
    return (
      (Array.isArray(router.query.create_org_success)
        ? router.query.create_org_success[0]
        : router.query.create_org_success) || 'false'
    );
  }, [router.query.create_org_success]);

  const createOrgCancelled = useMemo(() => {
    return (
      (Array.isArray(router.query.create_org_canceled)
        ? router.query.create_org_canceled[0]
        : router.query.create_org_canceled) || 'false'
    );
  }, [router.query.create_org_canceled]);

  const createOrgSessionID = useMemo(() => {
    return (
      (Array.isArray(router.query.session_id)
        ? router.query.session_id[0]
        : router.query.session_id) || undefined
    );
  }, [router.query.session_id]);

  useGetNewOrganization(createOrgSuccess === 'true' ? createOrgSessionID : undefined);

  const toast = useToast();

  const orgCreationCancelledCallback = useCallback(() => {
    toast({
      status: 'error',
      title: 'Organization Creation Cancelled',
      description: `Please try again or contact support`,
      duration: 3000,
      isClosable: true,
    });

    router.replace(window.location.href);
  }, [toast, router]);

  useEffect(() => {
    if (createOrgCancelled === 'true') {
      orgCreationCancelledCallback();
    }
  }, [orgCreationCancelledCallback, createOrgCancelled]);
}

export default useHandleOrgCreationCallback;
