import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/nextjs';
import { AxiosError, AxiosResponse } from 'axios';

import Axios, { AxiosApiNoAuth } from 'utils/Axios';

const TESTING = process.env.NEXT_PUBLIC_ENV === 'test';

// extends Axios headers with auth0 token
function AxiosExtend() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const interceptor = Axios.interceptors.request.use(async (config) => {
      try {
        const token = await getAccessTokenSilently();
        config.headers['Authorization'] = `JWT ${token}`;
      } catch (e: any) {
        console.error(e.message);
      }

      return config;
    });
    return () => {
      // clean up interceptor on code reload
      Axios.interceptors.request.eject(interceptor);
    };
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const onFulfilled = async (response: AxiosResponse) => {
      return Promise.resolve(response);
    };

    const onError = async (error: AxiosError<any>) => {
      // if (
      //   error?.code === 'ERR_NETWORK' &&
      //   error?.message === 'Network Error' &&
      //   !toast.isActive('ERR_NETWORK')
      // ) {
      //   toast({
      //     id: 'ERR_NETWORK',
      //     title: 'Network Error',
      //     description: 'Please check your network connection and refresh the page.',
      //     status: 'error',
      //     isClosable: true,
      //     duration: null,
      //   });
      // }

      if (error?.response?.data?.detail) {
        try {
          if (typeof error?.response?.data?.detail === 'string') {
            error.message = error?.response?.data?.detail;
          } else {
            error.message = JSON.stringify(error?.response?.data?.detail, null, 2);
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (error.response?.status === 422) {
        // log 422 to sentry
        if (!TESTING) {
          Sentry.captureException(error);
        }
      }

      return Promise.reject(error);
    };

    const interceptor = Axios.interceptors.response.use(onFulfilled, onError);
    const interceptorNoAuth = AxiosApiNoAuth.interceptors.response.use(onFulfilled, onError);

    return () => {
      // clean up interceptor on code reload
      Axios.interceptors.response.eject(interceptor);
      AxiosApiNoAuth.interceptors.response.eject(interceptorNoAuth);
    };
  }, []);

  return null;
}

export default AxiosExtend;
