import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import Router from 'next/router';

import { getNewOrganizationURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

type OrganizationResponse = {
  uid: string;
  slug: string;
  description: string;
};

function useGetNewOrganization(
  sessionID?: string,
  options?: UseQueryOptions<OrganizationResponse>,
) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const url = getNewOrganizationURL(sessionID!);

  const query = useQuery<OrganizationResponse>(
    ['sessionID', sessionID],
    async () => {
      const result = await Axios.get<OrganizationResponse>(url);
      await queryClient.invalidateQueries(['account']);
      setTimeout(() => {
        // add 1-second delay so toast isn't closed on page navigation
        toast({
          status: 'success',
          title: 'Organization Created',
          description: `You have successfully created organization: ${result.data.slug} `,
          duration: 3000,
          isClosable: true,
        });
      }, 1000);
      await Router.push({ pathname: `/new-org`, query: { new_org: result?.data?.uid } });

      return result?.data;
    },
    {
      ...options,
      enabled: !!sessionID,
    },
  );

  return query;
}

export default useGetNewOrganization;
